import { required, maxLength } from "vuelidate/lib/validators";
import { especiales, inputColor, onlyMayusText } from '@/_validations/validacionEspeciales';

export default () => {
    return {
        TpHolidayId: { required },
        HolidayName: { required, especiales, maxLength: maxLength(250) },
        FechaCompleta: { required },
    }
}

